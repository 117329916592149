/* eslint-disable radix */
import createScript from './helpers/createScript';
import getAllKeyValues from './helpers/getAllKeyValues';
import getLangIdFromUrl from './helpers/getLangIdFromUrl';
import getRegioFromCookie from './helpers/getRegioFromCookie';
import funnelEvents from './adapters/funnelEvents';
import Storage from './helpers/Storage';
import addUserIds from './helpers/addUserIds';
import asyncAddUserId from './helpers/asyncAddUserId';
import log from './helpers/logger';
import addExternalIds from './helpers/addExternalIds';
import getSegments from './helpers/getSegments';
import VideoPlayer from './adapters/VideoPlayer';
import cookieSyncs from './helpers/cookieSyncs';
import segmentSyncs from './helpers/segmentSyncs';

const dmps = addUserIds(DMP);
// temp async hack
const idPromise = asyncAddUserId(dmps);
const persist = PERSIST;
const cached = persist
  ? parseInt(Storage.get(persist.cache.name, persist.cache.type))
  : 0;

idPromise
  .then(dmp => {
    const init = event => {
      const cxjs = CONVERSION
        ? 'https://cdn.cxense.com/cx.cce.js'
        : 'https://cdn.cxense.com/cx.js';
      const siteid = getLangIdFromUrl(CXENSE_SITE_ID);

      // initialize
      window.cX = window.cX || CXINIT;
      createScript(cxjs);
      window.cX.shouldPoll = false;
      window.cX.callQueue = window.cX.callQueue || [];
      if (CONVERSION) {
        window.cX.CCE = window.cX.CCE || {};
        window.cX.CCE.callQueue = window.cX.CCE.callQueue || [];
      }
      let location = window.location.href;

      // Single Page Application
      if (event) {
        window.cX.callQueue.push(['initializePage']);
        log('initializePage');
        // this is for goplay app
        if (event.detail) {
          location = event.detail.url;
        }
      }

      // setSiteId
      window.cX.callQueue.push(['setSiteId', siteid]);

      if (!cached) {
        addExternalIds(dmp);
      }

      if (CUSTOM) {
        // setCustomParameters
        const cp = getAllKeyValues(CUSTOM);
        if (cp) {
          window.cX.callQueue.push(['setCustomParameters', cp]);
        }
      }

      // async actions which require the cX to be ready
      window.cX.callQueue.push([
        'invoke',
        () => {
          // start invoke
          // query string parameters to fetch from the URL
          if (URLARGS) {
            const urlArgs = window.cX.parseUrlArgs();
            const allowed = URLARGS;

            const cp = Object.keys(urlArgs)
              .filter(key => allowed.includes(key))
              .reduce((obj, key) => {
                obj[key] = urlArgs[key];
                return obj;
              }, {});

            window.cX.setCustomParameters(cp);
          }
          // end invoke
        },
      ]);

      // next 3 adapters use a default pqid from MH
      if (MH_SHOP)
        // webshop events
        funnelEvents();

      if (MH_REGIO) {
        // regio box on popular MH titles
        const pc = getRegioFromCookie();
        if (pc) {
          window.cX.callQueue.push([
            'setUserProfileParameters',
            { regiobox: [pc] },
          ]);
        }
      }

      // stuff that needs to be stored so it can be reused in adserving
      if (PERSIST) {
        if (!cached) {
          cookieSyncs(SYNC);
          segmentSyncs(dmp);
        }

        window.cX.callQueue.push([
          'invoke',
          () => {
            // register userid in localStorage
            const uid = window.cX.getUserId();
            Storage.set(persist.user.name, uid, persist.user.type);
            getSegments(dmp);
          },
        ]);
        // use cache flag to perform some actions only once per session
        Storage.set(persist.cache.name, 1, persist.cache.type);
      }

      // Send Page View Event
      if (window.location.pathname.match(FILTER)) {
        const options = {
          location,
          referrer: document.referrer,
          useAutoRefreshCheck: false,
        };
        CONVERSION
          ? window.cX.CCE.callQueue.push(['sendPageViewEvent', options])
          : window.cX.callQueue.push(['sendPageViewEvent', options]);
      }

      // Run CCE widget
      if (CONVERSION) {
        window.cX.CCE.callQueue.push(['run', CONVERSION]);
      }
    };

    // run on content reload
    if (TRIGGER) {
      const trigger = TRIGGER;

      // tell website this library is ready
      document.dispatchEvent(new CustomEvent(trigger.out));
      log(`sending '${trigger.out}' event`);
      // run this script as a pageview
      document.addEventListener(trigger.in, init);
      log(`listen to '${trigger.in}' for init`);
      // this is for logouts
      const clear = () => {
        Storage.del(persist.cache.name);
        log(`heard '${trigger.clear}' and cleared cache`);
      };
      // requested by Androme
      const close = () => {
        document.removeEventListener(trigger.in, init);
        document.removeEventListener(trigger.clear, clear);
        document.removeEventListener(trigger.close, close);
        log(`heard '${trigger.close}' and deafened`);
      };
      if (trigger.clear) {
        document.addEventListener(trigger.clear, clear);
        log(`listen to '${trigger.clear}' for cache clear`);
      }
      if (trigger.close) {
        document.addEventListener(trigger.close, close);
        log(`listen to '${trigger.close}' for cleanup`);
      }
      // run on every onload/refresh for non-SPA or hybrid SPA
    } else if (!TRIGGER || TRIGGER.hybrid) {
      log(`init`);
      init(false);
    }

    // SBS video events
    if (VIDEO) {
      const config = VIDEO;
      const player = new VideoPlayer(config, dmp);
      player.listen();
      if (config.close) {
        log(`listen to '${config.close}' for cleanup player`);
        document.addEventListener(config.close, () => {
          log(`heard '${config.close}' and deafened player`);
          player.deafen();
        });
      }
    }

    return false;
  })
  .catch();
